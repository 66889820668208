
import { ref } from 'vue'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { fromNow } from '@/utils/format'
import { Review } from 'momai'
import { pageCommentsToMe } from '@/api/user/student'

export default {

  setup: () => {
    const reviewList = ref<Review[]>([])

    const totalNum = ref(-1)
    const fetch: FetchFunction = (pageData, loadEnd) => {
      pageCommentsToMe(pageData).then(({ total, records }) => {
        reviewList.value.push(...records)
        totalNum.value = total
        loadEnd()
      })
    }

    const { loadMore, ...others } = useLoadList({
      fetch,
      total: totalNum,
      list: reviewList
    })

    return {
      totalNum,
      fromNow,
      reviewList,
      ...others,
      loadMore
    }
  }
}
